import React, { useEffect } from "react";
import OpsScreenWrapper from "../views/opsScreen/opsScreenTabs";
import { Router, RouteComponentProps } from "@reach/router";
import { Layout, PrivateRoute } from "../components";

export default (a: RouteComponentProps) => {
  return (
    <Layout>
      <Router
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >        
        <PrivateRoute component={OpsScreenWrapper} path="/ops" pagetitle="LDP Ops Screen" />
        <PrivateRoute component={OpsScreenWrapper} path="/ops/:buyer/:vwStat" pagetitle="LDP Ops Screen" />
        <PrivateRoute component={OpsScreenWrapper} path="/ops/:buyer/:status/:vwStat" pagetitle="LDP Ops Screen" />
      </Router>
    </Layout>
  );
};